.st0 {
    fill: var(--svg-fill-color);
    transition: fill 0.5s ease-in-out;
}

.language-toggle-text {
    font-size: 14px;
    transition: all 0.5s ease-in-out;
}

@media screen and (min-width: 780.0156250000499654px) {

    .layout {
        width: 100%;
        height: 60px;
        display: flex;
        padding: 0 20px;
        justify-content: space-between;
        align-items: center;
        background-color: var(--layout-bg-color);
        position: fixed;
        z-index: 9999;
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(6px);
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);

        .logo {
            width: 102px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.5s ease-in-out;
        }

        .logo:hover .st0 {
            // 填充流光效果
            fill: #6a6a6a;
        }
 
    }

    /* 默认情况下显示导航 */
    .nav {
        width: 580px;
        height: 40px;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // transition: all 0.3s ease-in-out;

        a {
            font-size: 16px;
            text-decoration: none;
        }

        a:link {
            color: white;
        }

        a:visited {
            color: rgb(255, 255, 255);
            /* 设置已访问的链接颜色 */
        }


        a:hover {
            color: #ffffff;
            /* 设置鼠标悬停时的颜色 */
        }

        /* 链接激活时（点击时） */
        a:active {
            color: rgb(255, 255, 255);
            /* 设置激活时的颜色 */
        }

        a[aria-current="page"] .nav-title {
            background-color: var(--layout-button-active-bg-color);

            /* 设置激活时的颜色 */
            span {
                color: var(--layout-button-active-text-color);
            }
        }

        /* 设定当前页面的链接颜色 */
        a[aria-current="page"] {
            color: rgb(0, 0, 0);
        }

        .nav-title {
            width: 100px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            background-color: var(--layout-button-normal-bg-color);
            transition: background-color 0.3s;
        }

        // 语言切换
        .language-toggle {
            width: 100px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            background-color: var(--language-toggle-bg-color);
            transition: background-color 0.3s;
            cursor: pointer;
        }

        .language-toggle:hover {
            background-color: var(--layout-button-hover-bg-color);
        }

        .nav-title:hover {
            background-color: var(--layout-button-hover-bg-color);
        }
    }

    /* 移动端导航样式 */
    .hamburger_btn {
        display: none;
    }
}

@media screen and (max-width: 779.99999999px) {

    .layout {
        width: 100%;
        height: 60px;
        display: flex;
        padding: 0 20px;
        background-color: var(--layout-bg-color);
        justify-content: space-between;
        position: fixed;
        z-index: 9999;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
        
        .logo {
            width: 102px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .nav.closed {
        display: flex;
        align-items: center;
    }

    .nav {
        display: none;
        /* 默认隐藏 */
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        z-index: 999;

        a {
            font-size: 20px;
            text-decoration: none;
        }

        a:link {
            color: white;
        }

        a:visited {
            color: rgb(255, 255, 255);
            /* 设置已访问的链接颜色 */
        }


        /* 链接激活时（点击时） */
        a:active {
            color: rgb(255, 255, 255);
            /* 设置激活时的颜色 */
        }

        a[aria-current="page"] .nav-title {
            // color: orange;
            // background-color: #282828;
            background-color: var(--layout-button-active-bg-color);

            /* 设置激活时的颜色 */
            span {
                color: var(--layout-button-active-text-color);
                font-weight: bold;
            }
        }

        /* 设定当前页面的链接颜色 */
        a[aria-current="page"] {
            color: rgb(0, 0, 0);
        }
    }

    .nav.open {
        position: fixed;
        display: flex;
        width: 100%;
        height: 100vh;
    }

    .layout.layout-open {
        width: 100%;
        height: 100vh;
        justify-content: space-between;
    }

    .nav-title {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        // padding: 20px;
    }

    // 语言切换
    .language-toggle {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .language-btn:hover {
        background-color: #565656;
    }

    .hamburger_btn {
        display: flex;
        cursor: pointer;
        width: 32px;
        height: 60px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    // 第二种汉堡按钮
    button.hamburger {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        width: 32px;
        // margin: 0 16px;
        transition: all 0.25s ease-in-out;

        &>span {
            width: 100%;
            height: 2px;
            display: block;
            margin: 8px 0;
            background-color: var(  --hamburger-button-color);
        }
    }

    .h-4 {
        &>span {
            transition: all 0.2s ease-in-out;
        }

        &.hamburger-active {
            &>span {
                background-color: #e84f4f;
            }

            &>span:nth-child(2) {
                opacity: 0;
                transform: translateX(-100%);
            }

            &>span:nth-child(1) {
                transform: translateY(10px) rotateZ(135deg);
            }

            &>span:nth-child(3) {
                transform: translateY(-10px) rotateZ(-135deg);
            }
        }
    }
}


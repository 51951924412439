.image-wrapper {
    position: relative;
    width: 100%;
    height: 100%; // 或根据实际需求调整
    overflow: hidden;
    border-radius: inherit;
  }
  
  .skeleton-loader {
    width: 100%;
    height: 100%;
    background-color: #333333; // 骨架屏颜色
    animation: pulse 3s infinite ease-in-out;
    border-radius: inherit;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
  
 .skeleton_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: opacity 0.3s;

    // width: auto;
    // height: auto;
    // // 等比例缩小
    // max-width: 100%;
    // max-height: 100%;
    // object-fit: contain;
    border-radius: 10px;
  }
  
  .hidden {
    opacity: 0;
  }
  

  .st5{fill:#818181;}
  .st6{fill:#ff3e3e;}
.logo_creater_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .logos_svg {
    transition: fill 0.3s ease-in-out;
    fill: var(--logos-svg-fill);
  }
}

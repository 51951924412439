@media screen and (min-width: 780.0156250000499654px) {
    .talk-to-us {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        // background-color: #1a1a1a;
        .a_link {
            width: 80px;
            height: 80px;

            .twitter {
                width: 80px;
                height: 80px;
                padding: 10px;
                background-color: #202020;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all .35s ease-in-out;

                .x-icon {
                    width: 40px;
                    height: 40px;

                    .x-icon-path {
                        transition: all .35s ease-in-out;
                    }
                }

            }
        }


        .twitter:hover {
            background-color: #ffffff;
        }

        .twitter:hover .x-icon-path {
            fill: rgb(0, 0, 0);
        }
    }
}

@media screen and (max-width: 779.99999999px) {
    .talk-to-us {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    
        // background-color: #1a1a1a;
        .a_link {
            width: 80px;
            height: 80px;
    
            .twitter {
                width: 80px;
                height: 80px;
                padding: 10px;
                background-color: #ffffff;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;    
                .x-icon {
                    width: 40px;
                    height: 40px;
    
                    .x-icon-path {
                        fill: black;
                    }
                }
    
            }
        }
    
    }
}
.mainScene-container {
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    // background: linear-gradient(90deg, #1b1b1b 1px, rgba(0, 0, 0, 0) 1%) 95px 100px, linear-gradient(#000000 8px, rgba(32, 32, 32, 0) 1%), rgb(44, 44, 44);
    // background-size: 5px 5px;

    background-image: radial-gradient(circle, rgb(51, 51, 51) 1px, rgba(0, 0, 0, 0) 1px);
    background-size: 16px 16px;
}

h1 {
    margin: 0;
    color: var(--text-color);
    font-size: 40px;
}

.typing-text::after {
    content: "|";
    animation: blink ease 1s infinite;
}

@keyframes blink {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.slogan {
    font-size: 16px;
}



@media screen and (min-width: 780.0156250000499654px) {
    .typewriter-container {
        width: 100%;
        height: 160px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .typing-text {
        font-size: 60px;
        font-weight: 600;
    }

    .slogan {
        font-size: 30px;
    }
}

@media screen and (max-width: 779.99999999px) {
    .typewriter-container {
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .typing-text {
        font-size: 36px;
        font-weight: 600;
    }

    .slogan {
        font-size: 18px;
    }
}
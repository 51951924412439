.welcome-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--welcome-bg-color);
    backdrop-filter: blur(10px);
    position: fixed;
    z-index: 99999999;

    .welcome-button {
        width: 120px;
        height: 40px;
        background-color: var(--welcome-button-color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        margin: 40px 0;

        .welcome-button-text {
            font-size: 18px;
            font-weight: 600;
            color: var(--welcome-text-color);
        }
    }

    .welcome-tip {
        font-size: 14px;
        color: var(--welcome-tips-text-color);
        animation: tips 3s infinite linear;
    }
}

@keyframes tips {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1
    }
}
[data-theme='dark'] {
  --language-toggle-bg-color: #303030;
  --bg-color: #000000;
  --text-color: #ffffff;
  --layout-bg-color: rgba(25, 25, 25, 0.75);
  --svg-fill-color: #ffffff;
  --layout-button-active-bg-color: #ffffff;
  --layout-button-active-text-color: #000000;
  --layout-button-hover-bg-color: #5f5f5f;
  --layout-button-normal-bg-color: #313131;
  --layout-button-normal-text-color: #ffffff;
  --dialog-bg-color: rgba(0, 0, 0, 0.85);
  --hamburger-button-color: #ffffff;
  --welcome-bg-color: rgba(0, 0, 0, 0.85);
  --welcome-text-color: #000000;
  --welcome-button-color: #ffffff;
  --welcome-tips-text-color: #ffffff;
  --logos-svg-fill: #ffffff;
}

[data-theme='light'] {
  --language-toggle-bg-color: #eeeeee;
  --bg-color: #ffffff;
  --text-color: #000000;
  --layout-bg-color: rgba(255, 255, 255, 0.75);
  --svg-fill-color: #000000;
  --layout-button-active-bg-color: #000000;
  --layout-button-active-text-color: #ffffff;
  --layout-button-hover-bg-color: #e9e9e9;
  --layout-button-normal-bg-color: #f5f5f5;
  --layout-button-normal-text-color: #000000;
  --dialog-bg-color: rgba(255, 255, 255, 0.85);
  --hamburger-button-color: #000000;
  --welcome-bg-color: rgba(255, 255, 255, 0.85);
  --welcome-text-color: #ffffff;
  --welcome-button-color: #000000;
  --welcome-tips-text-color: #000000;
  --logos-svg-fill: #000000;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}


span {
  color: var(--text-color);
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* filter: grayscale(1); */
  // cursor: none;
  touch-action: manipulation; // 防止长按出现菜单

  -webkit-tap-highlight-color: transparent; // 去除点击高亮
  -webkit-touch-callout: none; // 禁止长按弹出菜单
  outline: none;
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
}


p {
  color: aliceblue;
}
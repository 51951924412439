.main-container-1 {
    width: 100%;
    height: auto;
    padding: 10px;
    // background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
}

span {
    color: var(--text-color);
    z-index: 999;
}

.title-1 {
    margin: 80px 0 40px 0;
    color: var(--text-color);
    font-size: clamp(40px, 5vw, 60px);
    font-weight: 600;
}

.poster {
    max-width: 1800px;
    width: 100%;
    height: auto;
    // height: 660px;
    overflow: scroll;
    // background-color: rgb(27, 27, 27);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}


@media screen and (min-width: 780.0156250000499654px) {
    .item {
        width: 360px;
        // height: auto;
        height: 360px;
        max-height: 360px;
        margin: 10px 10px;
        background-color: var(--bg-color);
        box-shadow: inset 0 0 0 1px rgba(100, 100, 100, 0.5);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .icon {
            position: absolute;
            bottom: -6px;
            right: -6px;
            width: 80px;
            height: 80px;
            background-color: #000000;
            border-top-left-radius: 50%;
            /* overflow: hidden; */
        }

        .icon::before {
            content: "";
            position: absolute;
            bottom: 6px;
            left: -20px;
            width: 20px;
            height: 20px;
            border-bottom-right-radius: 20px;
            box-shadow: 6px 6px 0 6px #000000;
        }

        .icon::after {
            content: "";
            position: absolute;
            top: -20px;
            right: 6px;
            width: 20px;
            height: 20px;
            border-bottom-right-radius: 20px;
            box-shadow: 6px 6px 0 6px #000000;

        }

        .iconBox {
            position: absolute;
            width: 60px;
            height: 60px;
            bottom: 6px;
            right: 6px;
            background-color: #282828;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s;
        }

        .iconBox span {
            color: #fff;
            font-size: 24px;
        }

        .icon:hover .iconBox {
            background-color: #ffffff;
        }
    }

    .item-shu {
        border-radius: 10px;
        max-width: 360px;
        width: 100%;
        margin: 10px;
        /* 宽度根据容器自动调整 */
        aspect-ratio: 9 / 16;

        .item-shu-inside {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s ease-in-out;
            filter: grayscale(1);

            // background-color: #fff;
            .mask-shu {
                position: absolute;
                width: 100%;
                height: 100%;
                box-shadow: inset 0 0 16px 0px rgba(97, 97, 97, 0.5);
                border-radius: inherit;
                // background-color: rgba(46, 233, 52, 0.8);
                background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
                padding-bottom: 20px;
                padding-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                z-index: 9999; // 防止被遮挡
            }
        }

        .item-shu-inside:hover {
            filter: grayscale(0);
        }
    }

    /////////////////////////////
    .shu-previewdialog {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none !important;
        transform-origin: center;
        width: 450px;
        aspect-ratio: 9 / 16;
        background-color: rgba(36, 36, 36);
        animation: dia .35s ease-out;
        transform: translateY(-50%) translateX(-50%);
        border-radius: 10px;
    }

    // .longposter-previewdialog {
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     border: none !important;
    //     transform-origin: center;
    //     width: 360px;
    //     aspect-ratio: 2 / 3;
    //     background-color: rgba(36, 36, 36);
    //     animation: dia .35s ease-out;
    //     transform: translateY(-50%) translateX(-50%);
    //     border-radius: 10px;
    //     overflow: hidden;
    // }
    .longposter-previewdialog {
        top: 50%;
        left: 50%;
        // transform: translate(-50%, -50%);
        border: none !important;
        transform-origin: center;
        width: 750px;
        height: 100% !important;
        background-color: rgb(255, 255, 255);
        animation: dia .35s ease-out;
        transform: translateY(-50%) translateX(-50%);
        border-radius: 10px;
        overflow: hidden;
    }


    // .long-imgpreview_container {
    //     padding: 0em;
    //     border: none !important;
    //     min-width: 360px;
    //     width: 360px;
    //     overflow: scroll;
    //     aspect-ratio: 2/3;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: flex-start;
    //     align-items: center;
    // }
    .long-imgpreview_container {
        padding: 0em;
        border: none !important;
        height: inherit;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        // justify-content: flex-start;
        align-items: center;
    }


    .shu-imgpreview_container {
        padding: 0em;
        border: none !important;
        width: 450px;
        aspect-ratio: 9/16;
        // background-color: #ff0000;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;

        .shu-closebtn {
            position: absolute;
            bottom: 20px;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.2);
            box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.4);
            backdrop-filter: blur(6px);
            display: flex;
            justify-content: center;
            align-items: center;


            .st1 {
                fill: #fff;
                transition: all 350ms ease-in-out;
                transform-origin: center;
            }

        }

        .shu-closebtn:hover .st1 {
            transform: rotate(-90deg);
        }
    }

    /////////////////////////////


    .item-aftereffect {
        border-radius: 10px;
        max-width: 360px;
        width: 100%;
        margin: 10px;
        background-color: rgb(26, 26, 26);
        /* 宽度根据容器自动调整 */
        aspect-ratio: 16 / 9;

        display: flex;
        justify-content: center;
        align-items: center;

        .item-aftereffect-inside {
            // padding: 3px 1px;
            border-radius: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            transition: all 0.2s ease-in-out;
            filter: grayscale(1);
        }

        .item-aftereffect-inside:hover {
            filter: grayscale(0);
        }
    }

    .longposter-item {
        border-radius: 20px;
        max-width: 360px;
        width: 100%;
        margin: 10px;
        background-color: rgb(0, 0, 0);
        /* 宽度根据容器自动调整 */
        aspect-ratio: 2/3;

        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.35s ease-in-out;
        box-shadow: inset 0px 0px 2px 1px #363636;


        .item-longposter-inside {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }
    }

    .creative-item-aftereffect {
        border-radius: 20px;
        max-width: 360px;
        width: 100%;
        margin: 10px;
        background-color: var(--bg-color);
        /* 宽度根据容器自动调整 */
        aspect-ratio: 1/1;

        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.35s ease-in-out;
        box-shadow: inset 0px 0px 0 1px #444444;
    }

    .creative-item-aftereffect:hover {
        box-shadow: inset 0px 0px 2px 1px #545454;
    }
}

@media screen and (min-width: 1540px) {

    .item:last-child:nth-child(4n - 3),
    .item-shu:last-child:nth-child(4n - 3),
    .item-aftereffect:last-child:nth-child(4n - 3),
    .longposter-item:last-child:nth-child(4n - 3),
    .creative-item-aftereffect:last-child:nth-child(4n - 3) {
        margin-right: 1150px;
    }

    .item:last-child:nth-child(4n - 2),
    .item-shu:last-child:nth-child(4n - 2),
    .item-aftereffect:last-child:nth-child(4n - 2),
    .longposter-item:last-child:nth-child(4n - 2),
    .creative-item-aftereffect:last-child:nth-child(4n - 2) {
        margin-right: 770px;
    }

    .item:last-child:nth-child(4n - 1),
    .item-shu:last-child:nth-child(4n - 1),
    .item-aftereffect:last-child:nth-child(4n - 1),
    .longposter-item:last-child:nth-child(4n - 1),
    .creative-item-aftereffect:last-child:nth-child(4n - 1) {
        margin-right: 390px;
    }

}

@media screen and (min-width: 1160px) and (max-width: 1539.999999px) {

    .item:last-child:nth-child(3n - 2),
    .item-shu:last-child:nth-child(3n - 2),
    .item-aftereffect:last-child:nth-child(3n - 2),
    .longposter-item:last-child:nth-child(3n - 2),
    .creative-item-aftereffect:last-child:nth-child(3n - 2) {
        margin-right: 770px;
    }

    .item:last-child:nth-child(3n - 1),
    .item-shu:last-child:nth-child(3n - 1),
    .item-aftereffect:last-child:nth-child(3n - 1),
    .longposter-item:last-child:nth-child(3n - 1),
    .creative-item-aftereffect:last-child:nth-child(3n - 1) {
        margin-right: 390px;
    }

}


@media screen and (min-width: 800px) and (max-width: 1159.999999px) {

    .item:last-child:nth-child(2n - 1),
    .item-shu:last-child:nth-child(2n - 1),
    .item-aftereffect:last-child:nth-child(2n - 1),
    .longposter-item:last-child:nth-child(2n - 1),
    .creative-item-aftereffect:last-child:nth-child(2n - 1) {
        margin-right: 390px;
    }
}


// 屏幕宽度小于800px
@media screen and (max-width: 779.99999999px) {
    .item {
        // width: 360px;
        width: 100%;
        aspect-ratio: 1 / 1;
        margin: 10px;
        background-color: var(--bg-color);
        box-shadow: inset 0 0 0 1px rgba(100, 100, 100, 0.5);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;


        .icon {
            position: absolute;
            bottom: -6px;
            right: -6px;
            width: 80px;
            height: 80px;
            background-color: #000000;
            border-top-left-radius: 50%;
            /* overflow: hidden; */
        }

        .icon::before {
            content: "";
            position: absolute;
            bottom: 6px;
            left: -20px;
            width: 20px;
            height: 20px;
            border-bottom-right-radius: 20px;
            box-shadow: 6px 6px 0 6px #000000;
        }

        .icon::after {
            content: "";
            position: absolute;
            top: -20px;
            right: 6px;
            width: 20px;
            height: 20px;
            border-bottom-right-radius: 20px;
            box-shadow: 6px 6px 0 6px #000000;

        }

        .iconBox {
            position: absolute;
            /* inset: 10px; */
            width: 60px;
            height: 60px;
            bottom: 6px;
            right: 6px;
            background-color: #282828;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s;
        }

        .iconBox span {
            color: #fff;
            font-size: 24px;
        }

        .icon:hover .iconBox {
            background-color: #363636;
        }
    }

    .item-shu {
        border-radius: 10px;
        // max-width: 360px;
        width: 100%;
        margin: 10px;
        /* 宽度根据容器自动调整 */
        aspect-ratio: 9 / 16;
        /* 设置宽高比为 9:16 */

        .item-shu-inside {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .mask-shu {
                position: absolute;
                width: 100%;
                aspect-ratio: 9 / 16;
                box-shadow: inset 0 0 16px 0px rgba(97, 97, 97, 0.25);
                border-radius: inherit;
                background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
                padding-bottom: 20px;
                padding-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }
    }

    //////////////////////////////////////////
    .shu-previewdialog {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none !important;
        transform-origin: center;
        width: 360px;
        aspect-ratio: 9 / 16;
        background-color: rgba(36, 36, 36);
        animation: dia .35s ease-out;
        transform: translateY(-50%) translateX(-50%);
        border-radius: 10px;
    }

    .longposter-previewdialog {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none !important;
        transform-origin: center;
        width: 360px;
        // height: 100% !important;
        aspect-ratio: 2 / 3;
        background-color: rgba(36, 36, 36);
        animation: dia .35s ease-out;
        transform: translateY(-50%) translateX(-50%);
        border-radius: 10px;
        overflow: hidden;
        // position: absolute;
        // position: relative;
    }

    .longposter-item_container {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none !important;
        transform-origin: center;
        width: 360px;
        aspect-ratio: 2 / 3;
        background-color: rgba(36, 36, 36);
        animation: dia .35s ease-out;
        transform: translateY(-50%) translateX(-50%);
        border-radius: 10px;
    }

    .shu-imgpreview_container {
        padding: 0em;
        border: none !important;
        // min-width: 360px;
        // width: 360px;
        aspect-ratio: 9/16;
        // background-color: #ff0000;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;

        .shu-closebtn {
            position: absolute;
            bottom: 20px;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.2);
            box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.4);
            backdrop-filter: blur(6px);
            display: flex;
            justify-content: center;
            align-items: center;


            .st1 {
                fill: #fff;
            }
        }
    }


    .long-imgpreview_container {
        padding: 0em;
        border: none !important;
        // min-width: 360px;
        // width: 360px;
        overflow: scroll;
        height: 100%;
        // aspect-ratio: 2/3;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    //////////////////////////////////////////


    .item-aftereffect {
        border-radius: 10px;
        min-width: 360px;
        width: 100%;
        margin: 10px;
        // background-color: rgb(26, 26, 26);
        /* 宽度根据容器自动调整 */
        aspect-ratio: 16 / 9;
        /* 设置宽高比为 9:16 */

        display: flex;
        justify-content: center;
        align-items: center;

        .item-aftereffect-inside {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }
    }


    .longposter-item {
        border-radius: 20px;
        min-width: 360px;
        width: 100%;
        margin: 10px;
        background-color: rgb(20, 20, 20);
        /* 宽度根据容器自动调整 */
        aspect-ratio: 2/3;
        /* 设置宽高比为 9:16 */

        display: flex;
        justify-content: center;
        align-items: center;

        .item-longposter-inside {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }
    }

    .creative-item-aftereffect {
        border-radius: 20px;
        min-width: 360px;
        width: 100%;
        margin: 10px;
        background-color: var(--bg-color);
        box-shadow: inset 0 0 0 1px rgba(100, 100, 100, 0.5);
        /* 宽度根据容器自动调整 */
        aspect-ratio: 1/1;
        /* 设置宽高比为 9:16 */

        display: flex;
        justify-content: center;
        align-items: center;

        // .item-aftereffect-inside {
        //     border-radius: 10px;
        //     width: 100%;
        //     height: 100%;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     overflow: hidden;
        // }
    }

}

.poster-shu {
    max-width: 1800px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.aftereffect {
    max-width: 1800px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}


.longposter {
    max-width: 1800px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.creative-aftereffect {
    max-width: 1800px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}



/////////////////////////////
/// 

// 预览图弹窗相关样式
.previewdialog {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none !important;
    transform-origin: center;
    width: 720px;
    aspect-ratio: 16 / 9;
    background-color: rgba(36, 36, 36);
    animation: dia .35s ease-out;
    transform: translateY(-50%) translateX(-50%);
    border-radius: 10px;
}



.imgpreview_container {
    padding: 0em;
    border: none !important;
    aspect-ratio: 16/9;
    // background-color: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

// .long-imgpreview_container {
//     padding: 0em;
//     border: none !important;
//     aspect-ratio: 2/3;
//     // background-color: #ff0000;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     overflow: hidden;
// }



.previewdialog::backdrop {
    background-color: var(--dialog-bg-color);
    backdrop-filter: blur(6px);
}

.shu-previewdialog::backdrop {
    background-color: var(--dialog-bg-color);
    backdrop-filter: blur(6px);
}

.longposter-previewdialog::backdrop {
    background-color: var(--dialog-bg-color);
    backdrop-filter: blur(6px);
}

.previewdialog:focus {
    outline: none;
    border: none;
}

.shu-previewdialog:focus {
    outline: none;
    border: none;
}

.longposter-previewdialog:focus {
    outline: none;
    border: none;
}

.previewdialog::after {
    border: none;
}

.shu-previewdialog::after {
    border: none;
}

.longposter-previewdialog::after {
    border: none;
}

.imgpreview {
    width: auto;
    height: auto;
    // 等比例缩小
    max-width: 100%;
    max-height: 100%;
    object-fit: contain
}

.shu-imgpreview {
    width: auto;
    height: auto;
    // 等比例缩小
    max-width: 100%;
    max-height: 100%;
    object-fit: contain
}

.longposter-imgpreview {
    width: auto;
    height: auto;
    max-width: 100%;
    object-fit: contain;
}

.picture {
    width: auto;
    height: auto;
    // 等比例缩小
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 10px;
}

@keyframes dia {
    0% {
        transform-origin: top left;
        transform: scale(0) translateY(-50%) translateX(-50%);
    }

    100% {
        transform-origin: top left;
        transform: scale(1) translateY(-50%) translateX(-50%);
    }
}

// 鼠标svg元素样式
.mouse-effect {
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #fff;
    position: absolute;
    bottom: 20px;
    /* 距离底部的距离 */
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999999999;
    /* 确保在最上层 */
    pointer-events: none;
    /* 使鼠标事件穿透到底层 */
}


.st3 {
    fill: #353535;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.st4 {
    fill: #ffffff;
    transition: all .35s ease-out;
    animation: mouse 3s infinite;
}

@keyframes mouse {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }

    100% {
        transform: translateY(30px);
        opacity: 0;
    }
}